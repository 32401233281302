import React, { useEffect } from "react"
import { useSpring, useTransform } from "framer-motion"
import Copy from "./conf_content-copy"
import Desktop from "./conf_content-desktop"
import Phone from "./conf_content-phone"
import styled from "styled-components"
import { useMitt } from "react-mitt"

function Content(props) {
  const { emitter } = useMitt()
  let mouseX = useSpring(0, { restDelta: 0.001, restSpeed: 0.001 })
  let mouseY = useSpring(0, { restDelta: 0.001, restSpeed: 0.001 })
  let titleX = useTransform(mouseX, (value) => value / 300)
  let titleY = useTransform(mouseY, (value) => value / 300)
  let paraX = useTransform(mouseX, (value) => value / 400)
  let paraY = useTransform(mouseY, (value) => value / 400)
  let logosX = useTransform(mouseX, (value) => value / 500)
  let logosY = useTransform(mouseY, (value) => value / 500)
  let mobileX = useTransform(mouseX, (value) => value / 150)
  let mobileY = useTransform(mouseY, (value) => value / 150)
  let mobileRz = useTransform(mouseX, (value) => value / 1100)
  let desktopX = useTransform(mouseX, (value) => value / 200)
  let desktopY = useTransform(mouseY, (value) => value / 200)

  useEffect(() => {
    emitter.on("mouseX", (event) => {
      const offsetX = event.data - window.innerWidth / 2
      mouseX.set(offsetX)
    })

    emitter.on("mouseY", (event) => {
      const offsetY = event.data - window.innerHeight / 2
      mouseY.set(offsetY)
    })
  }, [emitter, mouseX, mouseY])

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
      }}
    >
      <ContentWrapper>
        <CopyWrapper>
          <Copy
            titleX={titleX}
            titleY={titleY}
            paraX={paraX}
            paraY={paraY}
            logosX={logosX}
            logosY={logosY}
          />
        </CopyWrapper>

        <GraphicContainer>
          <Desktop desktopX={desktopX} desktopY={desktopY} />
          <PhoneWrapper>
            <Phone mobileX={mobileX} mobileY={mobileY} mobileRz={mobileRz} />
          </PhoneWrapper>
        </GraphicContainer>
      </ContentWrapper>
    </div>
  )
}

const ContentWrapper = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90%;
  max-width: 1100px;
  @media only screen and (max-width: 2000px) {
    width: 90%;
    /* max-width: 1100px; */
  }
  @media only screen and (max-width: 1150px) {
    width: 80%;
  }
  @media only screen and (max-width: 900px) {
    flex-direction: column;
    width: 70%;
    margin-bottom: 10px;
  }
  @media only screen and (max-width: 650px) {
    width: 90%;
  }
`

const CopyWrapper = styled.div`
  position: relative;
  z-index: 3;
  /* margin-left: 2vw; */
  order: 1;
  margin: 0 3vw 0 2vw;
  @media only screen and (max-width: 900px) {
    order: 2;
  }
`

const GraphicContainer = styled.div`
  position: relative;
  height: 400px;
  min-width: 400px;
  order: 2;
  @media only screen and (max-width: 1000px) {
    height: 300px;
    min-width: 300px;
  }
  @media only screen and (max-width: 900px) {
    order: 1;
    margin-bottom: 3vh;
  }
  @media only screen and (max-width: 420px) {
    min-width: 200px;
    height: 200px;
    margin-bottom: 40px;
  }
`

const PhoneWrapper = styled.div`
  position: absolute;
  top: 36%;
  left: 6%;
  width: 40%;
  height: 60%;
`

export default Content
