import React from "react"
import { motion } from "framer-motion"
import { Paragraph } from "_data/global_styles"
import PageHeadline from "_components/misc/pageHeadline"
import {
  color_paragraph,
  color_paragraphHighlite,
} from "_src/assets/data/global_colors"

export default function Copy(props) {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
      }}
    >
      {Headline(props)}
      {Paragraph_(props)}
    </div>
  )
}

function Headline(props) {
  return (
    <motion.div
      initial={{ y: 30, opacity: 0, rotate: 0.01 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ delay: 0.55, duration: 1.45, ease: "easeInOut" }}
    >
      <motion.div style={{ x: props.titleX, y: props.titleY }}>
        <PageHeadline copy="Integrated video conferencing for you and your client." />
      </motion.div>
    </motion.div>
  )
}

function Paragraph_(props) {
  return (
    <motion.div
      initial={{ y: 35, opacity: 0, rotate: 0.01 }}
      animate={{ y: 0, opacity: 1 }}
      transition={{ delay: 0.5, duration: 1.45, ease: "easeInOut" }}
      style={{ marginLeft: ".5vw", color: "#303c6c" }}
    >
      <motion.div style={{ x: props.paraX, y: props.paraY }}>
        <Paragraph style={{ color: color_paragraph }}>
          Popular video conferencing providers Zoom, Doxy.me, Google Meet, Any
          Meeting, VSee Clinic, and Simple Practice are integrated within the{" "}
          {ActiveEmdr()} tools. We also include a {Generic()} that works with
          almost any web-based conference option.
        </Paragraph>
      </motion.div>
    </motion.div>
  )

  function Generic() {
    return (
      <span
        style={{
          fontWeight: "800",
          textDecoration: "none",
          color: color_paragraphHighlite,
        }}
      >
        Generic Connector
      </span>
    )
  }

  function ActiveEmdr() {
    return (
      <span
        style={{
          fontWeight: "800",
          textDecoration: "none",
          color: color_paragraphHighlite,
        }}
      >
        ActiveEMDR
      </span>
    )
  }
}
