import React from "react"
import styled from "styled-components"

function Balls(props) {
  return (
    <Wrapper>
      <Ball3 />
      <Ball2 />
      <Ball />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
`

const Ball = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #4387bd;
`

const Ball2 = styled.div`
  position: absolute;
  right: 1vw;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.5;
  background-color: #4387bd;
`

const Ball3 = styled.div`
  position: absolute;
  right: 2vw;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0.25;
  background-color: #4387bd;
`

export default Balls
