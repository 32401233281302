import React from "react"
import { motion } from "framer-motion"
import { StaticImage } from "gatsby-plugin-image"
import Balls from "./conf_balls"
import styled from "styled-components"

function Desktop(props) {
  return (
    <motion.div
      style={{ height: "100%", width: "100%" }}
      initial={{ opacity: 0, rotate: -6 }}
      animate={{ opacity: 1, rotate: 2 }}
      transition={{
        type: "spring",
        mass: 1.5,
        damping: 11,
        stiffness: 40,
        restDelta: 0.001,
        restSpeed: 0.001,
        delay: 1,
      }}
    >
      <Content style={{ x: props.desktopX, y: props.desktopY }}>
        <DesktopShadow
          initial={{ rotate: 4 }}
          animate={{ rotate: 4 }}
          transition={{ delay: 1, duration: 0.5 }}
        />

        <DesktopObj />
        <BallsWrapper
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 1, duration: 0.5 }}
        >
          <Balls />
        </BallsWrapper>
        <Window>
          <StaticImage src="vidConf_woman.jpg" alt="Therapist conferencing" />
        </Window>
      </Content>
    </motion.div>
  )
}

const Content = styled(motion.div)`
  position: absolute;
  display: flex;
  align-items: center;
  left: 0;
  top: 0;
  width: 100%;
  height: 95%;
`

const BallsWrapper = styled(motion.div)`
  position: absolute;
  width: 15%;
  height: 15%;
  top: 25%;
  left: 40%;
`

const Window = styled.div`
  position: absolute;
  right: 10%;
  bottom: 18%;
  width: 30%;
`

const DesktopObj = styled(motion.div)`
  background-color: #ccd6dd;
  position: absolute;
  width: 100%;
  height: 70%;
  border-radius: 5%;
  box-shadow: 0 10px 15px 0px rgba(50, 50, 73, 0.65);
`

const DesktopShadow = styled(motion.div)`
  background-color: #ae54d5;
  position: absolute;
  width: 100%;
  height: 70%;
  border-radius: 5%;
  box-shadow: 0 12.5px 100px -10px rgba(50, 50, 73, 0.65),
    0 10px 10px -5px rgba(50, 50, 73, 0.5);
`

export default Desktop
