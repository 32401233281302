import React from "react"
import { motion } from "framer-motion"
import { StaticImage } from "gatsby-plugin-image"
import Balls from "./conf_balls"
import styled from "styled-components"

export default function Phone(props) {
  return (
    <motion.div
      style={{ height: "100%", width: "100%" }}
      initial={{ opacity: 0, scale: 0.85, rotate: 3, translateY: 20 }}
      animate={{ opacity: 1, scale: 1, rotate: -3, translateY: 10 }}
      transition={{
        delay: 0.85,
        type: "spring",
        mass: 1,
        damping: 8,
        stiffness: 40,
        restDelta: 0.001,
        restSpeed: 0.001,
      }}
    >
      <Frame
        whileHover={{ scale: 1.02 }}
        transition={{
          type: "spring",
          mass: 1,
          damping: 9,
          stiffness: 50,
          restDelta: 0.001,
          restSpeed: 0.001,
        }}
        style={{ x: props.mobileX, y: props.mobileY, rotate: props.mobileRz }}
      >
        <Top>
          <BallsWrapper>
            <Balls />
          </BallsWrapper>
        </Top>
        <Bottom>
          <Image>
            <StaticImage
              src="vidConf_mobile.jpg"
              alt="Mobile device conferencing"
            />
          </Image>
        </Bottom>
      </Frame>
    </motion.div>
  )
}

const Frame = styled(motion.div)`
  position: absolute;
  background-color: #664077;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  box-shadow: 2px 7px 7px 1px rgba(50, 50, 73, 0.5);
  @media only screen and (max-width: 1000px) {
    border-radius: 10px;
  }
`

const Top = styled.div`
  background-color: #f1b593;
  position: relative;
  top: 4%;
  left: 4%;
  height: 36%;
  width: 92%;
  margin: 0;
  padding: 0;
  border-radius: 15px 15px 0 0;
  @media only screen and (max-width: 1000px) {
    border-radius: 10px 10px 0 0;
  }
`

const BallsWrapper = styled.div`
  position: absolute;
  width: 25%;
  height: 40%;
  top: 30%;
  left: 40%;
`

const Bottom = styled.div`
  background-color: #828aa2;
  position: relative;
  height: 56%;
  width: 92%;
  margin: 0;
  top: 4%;
  left: 4%;
  padding: 0;
  border-radius: 0 0 15px 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 1000px) {
    border-radius: 0 0 10px 10px;
  }
`

const Image = styled.div`
  height: 45%;
  width: 45%;
  top: 10%;
  position: absolute;
`
